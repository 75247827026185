// 动态加载页面
const loadContent = function () {
    var hash = location.hash

    // 兼容切换网站
    if (hash.startsWith('#/pages/sys/')) {
        document.getElementById('org_tree_show').style.display = 'block'
        document.getElementById('website_tree_show').style.display = 'none'
    } else if (hash.startsWith('#/pages/web/')) {
        document.getElementById('org_tree_show').style.display = 'none'
        document.getElementById('website_tree_show').style.display = 'block'
    } else {
        document.getElementById('org_tree_show').style.display = 'none'
        document.getElementById('website_tree_show').style.display = 'none'
    }

    hash = hash.replace('#/', '')
    if (hash == '' || hash == 'index.html') {
        //如果是首页，内容取dashboard的
        hash = 'pages/dashboard'
    }
    let index = hash.indexOf('?')
    let query = ''
    if (index !== -1) {
        // 携带了参数
        let temp = hash.split('?')
        hash = temp[0]
        query = '?' + temp[1]
    }
    var xhr = new XMLHttpRequest() || new ActiveXObject('Microsoft.XMLHTTP') || new ActiveXObject('Msxml2.XMLHTTP')
    if (xhr != null) {
        xhr.onreadystatechange = function () {
            if (xhr.readyState != 4) {
                //加载中
                document.getElementById('index-content').innerHTML = ''
            } else if (xhr.readyState == 4 && xhr.status == 200) {
                //成功

                var content = document.getElementById('index-content')
                content.style.opacity = 0 // 透明度
                setTimeout(function () {
                    document.getElementById('index-content').innerHTML = xhr.responseText // 内容加载

                    // 清除前一个页面的css和js
                    var head = document.getElementsByTagName('head')[0].childNodes
                    for (var i = 0; i < head.length; i++) {
                        if (head[i].mark && head[i].mark == 'dms') {
                            head[i].remove()
                            i = i - 1
                        }
                    }

                    loadCSS(hash + '.css')
                    loadJS(hash + '.js')
                    content.style.opacity = 1 // 透明度

                    document.body.setAttribute('style', 'padding-right:0px;overflow:auto;') //修复抽屉不关闭切换页面时，滚动条消失问题
                }, 200)
            } else {
                //找不到，返回404
                document.getElementById('index-content').innerHTML = load404()
            }
        }
        let url = query ? `${hash}.html${query}` : `${hash}.html`
        xhr.open('GET', url, false)
        xhr.send()
    }
}

const load404 = function () {
    var xhr = new XMLHttpRequest() || new ActiveXObject('Microsoft.XMLHTTP') || new ActiveXObject('Msxml2.XMLHTTP')
    if (xhr != null) {
        xhr.open('GET', '404.html', false)
        xhr.send()
        return xhr.responseText
    }
}

const loadCSS = function (path) {
    var head = document.getElementsByTagName('head')[0]
    var link = document.createElement('link')
    link.href = path
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.mark = 'dms' // dms 标志，在加载新页面前会清理这个标志的资源
    head.appendChild(link)
}

const loadJS = function (path) {
    var head = document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.src = path
    script.type = 'text/javascript'
    script.mark = 'dms' // dms 标志，在加载新页面前会清理这个标志的资源
    head.appendChild(script)
}

window.loadContent = loadContent
window.load404 = load404
window.loadCSS = loadCSS
window.loadJS = loadJS
