/*
 * @Author: huangzc
 * @Date: 2023-11-15 09:46:58
 * @LastEditors: huangzc
 * @LastEditTime: 2023-11-24 17:04:22
 * @Description: 全局loading方法
 */

/**
 * @description: 显示loading
 * @return {*}
 */
function showLoading() {
    let wrapper = document.getElementsByClassName('wrapper')[0]
    let mask = document.createElement('div')
    mask.className = 'loading-mask'
    mask.setAttribute('id', 'loading')
    let loadingGif = document.createElement('img')
    loadingGif.src = 'img/loading.gif'
    loadingGif.className = 'loading-gif'
    mask.appendChild(loadingGif)
    wrapper.appendChild(mask)
    document.querySelector('body').style.overflow = 'hidden'
}

/**
 * @description: 隐藏loading
 * @return {*}
 */
function hideLoading() {
    let loading = document.getElementById('loading')
    loading.remove()
    document.querySelector('body').style.overflow = 'auto'
}

window.showLoading = showLoading
window.hideLoading = hideLoading
