Date.prototype.Format = function (fmt) {
    var o = {
        'M+': this.getMonth() + 1, //月份
        'd+': this.getDate(), //日
        'h+': this.getHours(), //小时
        'm+': this.getMinutes(), //分
        's+': this.getSeconds(), //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    return fmt
}

/**
 * 时间转字符串
 * @param {Date} date 日期时间
 * @param {string} type 输出字符串类型，Y:年, m:月, d:日,w:星期, H：时, i:分, s:秒
 * @returns
 */
const date_format = function (date, type = 'Y-m-d') {
    if (!date) return null
    let arr = []
    type = type.toLowerCase()
    for (let i = 0; i < type.length; i++) {
        let r = type[i]
        switch (r) {
            case 'y':
                let y = date.getFullYear()
                arr.push(y)
                break
            case 'm':
                let m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                arr.push(m)
                break
            case 'd':
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                arr.push(d)
                break
            case 'h':
                let h = date.getHours()
                h = h < 10 ? '0' + h : h
                arr.push(h)
                break
            case 'i':
                let i = date.getMinutes()
                i = i < 10 ? '0' + i : i
                arr.push(i)
                break
            case 's':
                let s = date.getSeconds()
                s = s < 10 ? '0' + s : s
                arr.push(s)
                break
            case 'w':
                let w = date.getDay()
                arr.push(this.$t(`Weeks.${w}`))
                break
            default:
                arr.push(r)
                break
        }
    }
    return arr.join('')
}

window.date_format = date_format
