// Usage : https://github.com/caroso1222/notyf
// 提示框组件
import { Notyf } from 'notyf'

// 提示信息国际化
var chinese = {
    success: '成功',
    networkError: '网络故障',
    timeout: '请求超时',
    expired: '登录信息过期'
}

var english = {
    success: 'Success',
    networkError: 'Network Error',
    timeout: 'Timeout',
    expired: 'Expired'
}

var language = chinese

Notyf.prototype.saySuccess = function () {
    new Notyf().open({
        type: 'success',
        message: language['success'],
        duration: 2000,
        background: window.theme['success'],
        position: { x: 'center', y: 'top' }
    })
}

Notyf.prototype.sayInfo = function (msg) {
    new Notyf().open({
        type: 'custom',
        message: msg,
        duration: 2000,
        background: window.theme['info'],
        position: { x: 'center', y: 'top' }
    })
}

Notyf.prototype.sayWarning = function (msg) {
    new Notyf().open({
        type: 'custom',
        message: msg,
        duration: 2000,
        background: window.theme['warning'],
        position: { x: 'center', y: 'top' }
    })
}

// sayError方法，内容为后端返回，国际化由后端实现
Notyf.prototype.sayError = function (msg) {
    new Notyf().open({
        type: 'error',
        message: msg,
        duration: 2000,
        background: window.theme['danger'],
        position: { x: 'center', y: 'top' }
    })
}

Notyf.prototype.networkError = function () {
    new Notyf().open({
        type: 'error',
        message: language['networkError'],
        duration: 2000,
        background: window.theme['danger'],
        position: { x: 'center', y: 'top' }
    })
}

Notyf.prototype.timeout = function () {
    new Notyf().open({
        type: 'error',
        message: language['timeout'],
        duration: 2000,
        background: window.theme['danger'],
        position: { x: 'center', y: 'top' }
    })
}

Notyf.prototype.expired = function () {
    new Notyf().open({
        type: 'error',
        message: language['expired'],
        duration: 2000,
        background: window.theme['danger'],
        position: { x: 'center', y: 'top' }
    })
}

window.Notyf = Notyf
