class SelectDate {
    data() {
        return {
            MonthArray: [
                { value: 0, label: '一月' },
                { value: 1, label: '二月' },
                { value: 2, label: '三月' },
                { value: 3, label: '四月' },
                { value: 4, label: '五月' },
                { value: 5, label: '六月' },
                { value: 6, label: '七月' },
                { value: 7, label: '八月' },
                { value: 8, label: '九月' },
                { value: 9, label: '十月' },
                { value: 10, label: '十一月' },
                { value: 11, label: '十二月' }
            ],
            WeekArray: [
                { value: 0, label: '星期日' },
                { value: 1, label: '星期一' },
                { value: 2, label: '星期二' },
                { value: 3, label: '星期三' },
                { value: 4, label: '星期四' },
                { value: 5, label: '星期五' },
                { value: 6, label: '星期六' }
            ]
        }
    }
    // 初始化
    init(e, data = { active: null, start: null, end: null }) {
        this.el = document.querySelector(e)
        let range = this.el.getAttribute('data-range')
        // 是否范围选择
        if (range) {
            this.is_range = true
            this.date_type = range

            this.start = data.start
            this.end = data.end
        }
        // this.el.querySelector('.label-box').addEventListener('click', () => {
        //   if (this.is_range) {
        //     this.start = data.start
        //     this.end = data.end
        //     this.setRange()

        //   } else {
        //     this.active = data.active
        //     setBox(this.date_type)
        //   }
        // })
    }

    labelClick() {
        if (this.is_range) {
            // this.start = data.start
            // this.end = data.end
            this.setRange()
        } else {
            this.active = data.active
            setBox(this.date_type)
        }
    }

    // 生成弹出选择框
    setRange() {
        let set_box = document.createElement('div')
        set_box.className = 'monthrange set-box rounded-3 d-flex flex-wrap bg-white'
        this.start_box = document.createElement('div')
        this.start_box.className = 'range-left col-6 p-3'
        this.end_box = document.createElement('div')
        this.end_box.className = 'range-right col-6 p-3'
        let date = new Date()
        this.start_year = this.start ? this.start.getFullYear() : date.getFullYear() - 1
        this.end_year = this.end ? this.end.getFullYear() : date.getFullYear()

        set_box.append(this.start_box)
        set_box.append(this.end_box)

        let foot = document.createElement('div')
        foot.className = 'foot col-12 px-3 py-2 border-top text-end'
        foot.innerHTML = `<button class="btn btn-primary">确定</button>`
        set_box.append(foot)

        this.el.append(set_box)

        switch (this.date_type) {
            // 月
            case 'month':
                this.MonthHTML(this.start_box, this.start_year)
                this.MonthHTML(this.end_box, this.end_year)
                break
            default:
                break
        }

        let root = set_box.parentElement
        let label_box = root.querySelector('.label-box')
        label_box.querySelector('.date-label[data-for="start"]').innerText = date_format(this.start, 'y-m')
        // label_box.querySelector('input[name="start"]').value = this.start
        label_box.querySelector('.date-label[data-for="end"]').innerText = date_format(this.end, 'y-m')
        // label_box.querySelector('input[name="end"]').value = this.end

        // 确定按钮
        set_box.querySelector('.foot .btn-primary').addEventListener('click', (e) => {
            // let root = set_box.parentElement;
            // let label_box = root.querySelector('.label-box')
            label_box.querySelector('.date-label[data-for="start"]').innerText = date_format(this.start, 'y-m')
            // label_box.querySelector('input[name="start"]').value = this.start
            label_box.querySelector('.date-label[data-for="end"]').innerText = date_format(this.end, 'y-m')
            // label_box.querySelector('input[name="end"]').value = this.end
            root.removeChild(set_box)
            label_box.querySelector('input[name="start"]').dispatchEvent(new MouseEvent('click'))
        })
    }

    // 生成月选择
    MonthHTML(el, year) {
        let html = `<div class="head pb-2 border-bottom d-flex justify-content-between align-items-center">
      <button class="btn prev-year"><i class="fa-solid fa-angles-left"></i></button>
      <span class="year">${year}</span>
      <button class="btn next-year"><i class="fa-solid fa-angles-right"></i></button>
        </div>
      <div class="body mt-2">
        <div class="row gy-2 gx-0">`

        let start_y = this.start ? this.start.getFullYear() : ''
        let start_m = this.start ? this.start.getMonth() : ''
        let end_y = this.end ? this.end.getFullYear() : ''
        let end_m = this.end ? this.end.getMonth() : ''

        this.data().MonthArray.forEach((r) => {
            // 范围选择
            if (this.is_range) {
                let cls_start = start_y == year && start_m == r.value ? ' start' : ''
                let cls_end = end_y == year && end_m == r.value ? ' end' : ''

                // 中间项
                let in_range = ''
                // 如果 start 和 end 已定义
                if (start_y && end_y) {
                    if (year > start_y && year < end_y) {
                        in_range = ' in-range'
                    } else if (year == start_y && start_y == end_y) {
                        if (r.value >= start_m && r.value <= end_m) {
                            in_range = ' in-range'
                        }
                    } else if (year == start_y && r.value >= start_m) {
                        in_range = ' in-range'
                    } else if (year == end_y && r.value <= end_m) {
                        in_range = ' in-range'
                    }
                }

                html += `<div class="col-3">
          <div class="item month${cls_start + cls_end + in_range}">
            <span>${r.label}</span>
          </div>
        </div>`
                // 单选
            } else {
                let cls_active = this.active.getMonth() === r.value ? ' active' : ''
                html += `<div class="col-3">
          <div class="item month${active + start + end}">
            <span>${r.label}</span>
          </div>
        </div>`
            }
        })
        html += `</div>
      </div>`
        el.innerHTML = html

        this.bind(el.querySelectorAll('.btn'), 'click', (e) => {
            this.setYear(e)
        })
        this.bind(el.querySelectorAll('.item'), 'click', (e) => {
            this.setMonth(e, el)
        })
    }

    // 年份控制
    setYear(e) {
        let header = e.parentElement
        let parent = header.parentElement

        if (parent.classList.contains('range-left')) {
            // 左边
            if (e.classList.contains('prev-year')) {
                if (this.start_year >= 2021) {
                    this.start_year--
                }
            } else if (e.classList.contains('next-year')) {
                if (this.start_year < this.end_year - 1) this.start_year++
            }
            this.MonthHTML(parent, this.start_year)
        } else {
            // 右边
            if (e.classList.contains('prev-year')) {
                if (this.end_year > this.start_year + 1) {
                    this.end_year--
                }
            } else if (e.classList.contains('next-year')) {
                this.end_year++
            }
            this.MonthHTML(parent, this.end_year)
        }
    }

    /**
     * 事件绑定
     * @param {Element} el DOM元素
     * @param {string} event 事件名
     * @param {function} callback 功能函数
     */
    bind(el, event, callback) {
        el.forEach((e) => {
            e.addEventListener(event, function () {
                callback(e)
            })
        })
    }

    setMonth(e, el) {
        let year = el.classList.contains('range-left') ? this.start_year : this.end_year
        let M = this.data().MonthArray.find((r) => r.label == e.innerText)
        let month = M.value
        if (this.end != null) {
            this.start = new Date()
            this.start.setFullYear(year)
            this.start.setMonth(month)
            this.end = null
        } else {
            let date = new Date()
            date.setFullYear(year)
            date.setMonth(month)
            if (date > this.start) {
                this.end = date
            } else {
                this.end = this.start
                this.start = date
            }
        }
        this.MonthHTML(this.el.querySelector('.range-left'), this.start_year)
        this.MonthHTML(this.el.querySelector('.range-right'), this.end_year)
    }
}

window.SelectDate = SelectDate
export default SelectDate
