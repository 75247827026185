// 配置文件
// 正式环境
// const static_url = window.location.protocol + '//' + window.location.host + '/' //静态资源路径
// const project_name = '/yatoo-web/' //前端工程名
// const back_url = static_url + 'yatoo-admin' //后端请求地址

const static_url = 'https://www.namkoo.cloud/' //静态资源路径
const project_name = '/' //前端工程名
const back_url = static_url + 'yatoo-admin' //后端请求地址

// 测试环境
// const static_url = 'http://192.168.0.13:8081/' //静态资源路径
// const project_name = '/' //前端工程名
// const back_url = static_url + 'yatoo-admin' //后端请求地址
// 请求
const request = function (api, method, data, token_flag, callback) {
    var token = localStorage.getItem('token')
    if (token_flag) {
        if (token == null || token == '') {
            new Notyf().expired()
            setTimeout(function () {
                window.location.href = project_name + 'sign-in.html'
            }, 600)
        }
    }

    var xhr = new XMLHttpRequest() || new ActiveXObject('Microsoft.XMLHTTP') || new ActiveXObject('Msxml2.XMLHTTP')
    if (xhr != null) {
        xhr.timeout = 10000
        xhr.open(method, back_url + api)
        xhr.setRequestHeader('Content-type', 'application/json')
        if (token_flag) xhr.setRequestHeader('token', token)
        if (data != null) {
            xhr.send(JSON.stringify(data))
        } else {
            xhr.send()
        }
        xhr.ontimeout = function () {
            new Notyf().timeout()
        }
        //监听
        xhr.onreadystatechange = function () {
            if (xhr.readyState !== 4) {
                return
            }
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
                var data = JSON.parse(xhr.responseText)
                if (data.code == 200) {
                    callback(xhr.responseText)
                } else if (data.code == 201) {
                    new Notyf().sayInfo('请修改密码')
                    setTimeout(function () {
                        window.location.href = project_name + 'reset-pwd.html'
                    }, 600)
                } else if (data.code == 203) {
                    new Notyf().expired()
                    setTimeout(function () {
                        window.location.href = project_name + 'sign-in.html'
                    }, 600)
                } else {
                    new Notyf().sayError(data.msg)
                }
            } else {
                new Notyf().networkError()
            }
        }
    }
}

const uploadFile = function (url, data, callback) {
    var xhr = new XMLHttpRequest() || new ActiveXObject('Microsoft.XMLHTTP') || new ActiveXObject('Msxml2.XMLHTTP')
    if (xhr != null) {
        showLoading()
        xhr.timeout = 60000
        xhr.open('POST', back_url + url)
        if (data != null) {
            xhr.send(data)
        } else {
            xhr.send()
        }
        xhr.ontimeout = function () {
            hideLoading()
            new Notyf().timeout()
        }
        //监听
        xhr.onreadystatechange = function () {
            if (xhr.readyState !== 4) {
                return
            }
            hideLoading()
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
                var data = JSON.parse(xhr.responseText)
                if (data.code == 200) {
                    callback(xhr.responseText)
                } else {
                    new Notyf().sayError(data.msg)
                }
            } else {
                new Notyf().networkError()
            }
        }
    }
}

const downloadFile = function (api, data, callback) {
    var token = localStorage.getItem('token')
    if (token == null || token == '') {
        new Notyf().expired()
        setTimeout(function () {
            window.location.href = project_name + 'sign-in.html'
        }, 600)
    }

    var xhr = new XMLHttpRequest() || new ActiveXObject('Microsoft.XMLHTTP') || new ActiveXObject('Msxml2.XMLHTTP')
    if (xhr != null) {
        xhr.open('POST', back_url + api)
        //设置请求头
        xhr.setRequestHeader('token', token)
        //设置响应类型
        xhr.setRequestHeader('Content-type', 'application/json')
        xhr.responseType = 'blob'
        xhr.onload = function (e) {
            if (this.status == 200) {
                var filename = xhr.getResponseHeader('Content-disposition').slice(20) //根据后台返回进行修改
                var blob = this.response
                var a = document.createElement('a')
                var url = URL.createObjectURL(blob)
                a.href = url
                a.download = filename
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            }
        }
        if (data != null) {
            xhr.send(JSON.stringify(data))
        } else {
            xhr.send()
        }
    }
}

window.uploadFile = uploadFile
window.request = request
window.downloadFile = downloadFile
window.project_name = project_name
window.static_url = static_url
